import Anchor from '../../components/other/Anchor';
import './main.scss';

const Main = () => {
    const $anchor = document.querySelector('.js-anchor');
    console.log(111, 'main init');
    new Anchor($anchor);
};

Main();
