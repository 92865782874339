import { getOffset } from '../../../utils/utils';

export default class Anchor {
    constructor(anchor) {
        this.$anchor = anchor;

        this.init();
    }

    init() {
        this.$anchor.addEventListener('click', this.goToBlock.bind(this));
    }

    goToBlock(e) {
        e.preventDefault();
        const href = this.$anchor.getAttribute('href');
        if (href.length) {
            const $targetBlock = document.querySelector(href);

            console.log(333, getOffset($targetBlock).top);
        }
    }
}
